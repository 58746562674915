import React, { Fragment, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import authAxios from "../../services/Token";
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import DatePicker from "react-datepicker";
import ApexCharts from 'react-apexcharts';
import Knob from "knob";
import ChartistChart from 'react-chartist';
import { smallchart1data, smallchart1option, smallchart2data, smallchart2option, smallchart3data, smallchart3option, smallchart4data, smallchart4option } from './chartsData/chartist-charts-data';
import { Currentlysale, Marketvalue } from './chartsData/apex-charts-data';
import { Send, Clock } from 'react-feather';
import { Dashboard, Summary, NewsUpdate, Appointment, Notification, MarketValue, Chat, New, Tomorrow, Yesterday, Daily, Weekly, Monthly, Store, Online, ReferralEarning, CashBalance, SalesForcasting, Purchase, Sales, SalesReturn, PurchaseRet, PurchaseOrderValue, ProductOrderValue, Pending, Yearly, Hot, Today, VenterLoren, Done, JohnLoren, Year, Month, Day, RightNow } from '../../constant';


const Default = () => {


  const [daytimes, setDayTimes] = useState()
  const today = new Date()
  const curHr = today.getHours()
  const curMi = today.getMinutes()
  const [meridiem, setMeridiem] = useState("AM")
  const startDate = new Date();
  const handleChange = date => {
    new Date()
  };
  let a = localStorage.getItem("admin")
  const [Administrator, setAdministrator] = React.useState(a);
  const [TiposMantenimientos, setTiposMantenimientos] = React.useState([]);
  const [Series, setSerie] = React.useState([]);
  const [Total, setTotal] = React.useState([]);
  const [Stotal, setStotal] = React.useState(0);
  const [Servicios, setServicio] = React.useState(0);
  const [Vehiculos, setVehiculos] = React.useState(0);
  const [Conductores, setConductores] = React.useState(0);
  const [Clientes, setClientes] = React.useState(0);
  const [Contratos, setContratos] = React.useState(0);
  const [Activos, setActivos] = React.useState(0);
  const [Proximos, setProximos] = React.useState(0);
  const [Vencidos, setVencidos] = React.useState(0);

  const [Activos2, setActivos2] = React.useState(0);
  const [Proximos2, setProximos2] = React.useState(0);
  const [Vencidos2, setVencidos2] = React.useState(0);

  const [Activos3, setActivos3] = React.useState(0);
  const [Proximos3, setProximos3] = React.useState(0);
  const [Vencidos3, setVencidos3] = React.useState(0);

  const [Activos4, setActivos4] = React.useState(0);
  const [Proximos4, setProximos4] = React.useState(0);
  const [Vencidos4, setVencidos4] = React.useState(0);
  const ListaTabla = async () => {

    authAxios.get(`dashboard/index`)
      .then((datosRespuesta) => {

        var f = datosRespuesta.data
        var status = datosRespuesta.data.status;

        setSerie([{
          name: '2023',
          data: [f.m1, f.m2, f.m3, f.m4, f.m5, f.m6, f.m7, f.m8, f.m9, f.m10, f.m11, f.m12, 0]
        }]);
        setTotal(f.total)
        setStotal(f.stotal)
        setServicio(f.serv_activo)
        setVehiculos(f.veh_activio)
        setConductores(f.cond_activo)
        setClientes(f.clientes)
        setContratos(f.contratos)
        setActivos(f.doc_activo)
        setProximos(f.prox_vencer)
        setVencidos(f.doc_vencidos)

        setActivos2(f.doc_activo2)
        setProximos2(f.prox_vencer2)
        setVencidos2(f.doc_vencidos2)

        setActivos3(f.doc_activo3)
        setProximos3(f.prox_vencer3)
        setVencidos3(f.doc_vencidos3)

        setActivos4(f.doc_activo4)
        setProximos4(f.prox_vencer4)
        setVencidos4(f.doc_vencidos4)
        setTiposMantenimientos(f.tipos_mantenimiento)

      })
      .catch();
  }
  React.useEffect(() => {
    ListaTabla();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Pagina Principal" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{Vehiculos}</h4><span>Vehiculos</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{Conductores}</h4><span>Conductores</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart2 flot-chart-container"
                            data={smallchart3data}
                            options={smallchart3option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{Clientes}</h4><span>Clientes</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media border-none align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart3 flot-chart-container"
                            data={smallchart4data}
                            options={smallchart4option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{Contratos}</h4><span>Contratos</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <Row className="m-0 chart-main">
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ApexCharts options={apexDashboard1.options} series={apexDashboard1.series} type="bar" height={50} />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{"1001"}</h4><span>{Purchase} </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ApexCharts options={apexDashboard2.options} series={apexDashboard2.series} type="bar" height={50} />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{"1005"}</h4><span>{Sales}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ApexCharts options={apexDashboard3.options} series={apexDashboard3.series} type="bar" height={50} />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{"100"}</h4><span>{SalesReturn}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media border-none align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ApexCharts options={apexDashboard4.options} series={apexDashboard4.series} type="bar" height={50} />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{"101"}</h4><span>{PurchaseRet}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row> */}
              </CardBody>
            </Card>
          </Col>
          <Col xl="6 xl-50" className="appointment-sec box-col-6">
            <Row>
              <Col xl="12" className="appointment">
                <Card>
                  <CardHeader className="card-no-border">
                    <div className="header-top">
                      <h5 className="m-0"> Elementos de Control</h5>

                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <div className="appointment-table table-responsive">
                      <table className="table table-bordernone">
                        <tbody>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/soat.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">SOAT</span><span className="font-roboto">Soat Proximos a vencer</span></td>

                            <td className="text-end">
                              <div className="button btn btn-warning">{Proximos}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>

                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/tecno.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">TECNOMECANICA</span><span className="font-roboto">TECNOMECANICA Proximos a vencer</span></td>

                            <td className="text-end">
                              <div className="button btn btn-warning">{Proximos2}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>

                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/tecno.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">REVISION PREVENTIVA BI</span><span className="font-roboto">REVISION PREVENTIVA BI Proximos a vencer</span></td>

                            <td className="text-end">
                              <div className="button btn btn-warning">{Proximos2}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>

                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/tecno.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">TARJETA DE OPERACION</span><span className="font-roboto">TARJETA DE OPERACION Proximos a vencer</span></td>

                            <td className="text-end">
                              <div className="button btn btn-warning">{Proximos2}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </Col>
          <Col xl="6 xl-50" className="appointment-sec box-col-6">
            <Row>
              <Col xl="12" className="appointment">
                <Card>
                  <CardHeader className="card-no-border">
                    <div className="header-top">
                      <h5 className="m-0"> Alertas</h5>

                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <div className="appointment-table table-responsive">
                      <table className="table table-bordernone">
                        <tbody>
                          {TiposMantenimientos.map((tipo) => {
                            return (
                              <tr key={tipo.id}>
                                <td><img className="img-fluid img-60 rounded-circle mb-3" src={'https://app.transorientesas.com/' + tipo.imagen} alt="" />
                                  <div className="status-circle bg-primary"></div>
                                </td>
                                <td className="img-content-box"><span className="d-block">MANTENIMIENTO {tipo.nombre}</span><span className="font-roboto">FRECUENCIA KILOMETRAJE: {tipo.kilometraje_frecuencia}</span></td>

                                <td className="text-end">
                                  <div className="button btn btn-warning">{Proximos}<i className="fa fa-check-circle ms-2"></i></div>
                                </td>

                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </Col>

          <Col xl="12 xl-100" className="dashboard-sec box-col-12">
            <Card className="earning-card">
              <CardBody className="p-0">
                <Row className="m-0">
                  <Col xl="3" className="earning-content p-0">
                    <Row className="m-0 chart-left">
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>Servicios</h5>
                        <p className="font-roboto"><div className="media-left"><i className="icofont icofont-car"></i>{Servicios} </div> </p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>En Proceso</h5>
                        <p className="font-roboto"><div className="media-left"><i className="icofont icofont-car"></i>{0} </div> </p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>Anulados</h5>
                        <p className="font-roboto"><div className="media-left"><i className="icofont icofont-car"></i>{0} </div> </p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>Completados</h5>
                        <p className="font-roboto"><div className="media-left"><i className="icofont icofont-car"></i>{0} </div> </p>
                      </Col>
                      <Col xl="12" className="p-0 left-btn"><a className="btn btn-gradient" href="/pages/14/Dubai">Ver mas</a></Col>
                    </Row>
                  </Col>
                  <Col xl="9" className="p-0">
                    <div className="chart-right">
                      <Row className="m-0 p-tb">
                        <Col xl="8" md="8" sm="8" className="col-12 p-0">
                          <div className="inner-top-left">
                            <ul className="d-flex list-unstyled">
                              <li>Factura mes x mes</li>

                            </ul>
                          </div>
                        </Col>
                        <Col xl="4" md="4" sm="4" className="col-12 p-0 justify-content-end">
                          <div className="inner-top-right">
                            <ul className="d-flex list-unstyled justify-content-end">
                              <li>Total de facturas {Total}</li>

                            </ul>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <CardBody className="p-0">
                            <div className="current-sale-container">
                              <ApexCharts id="chart-currently" options={Currentlysale.options} series={Series} type='area' height={250} />
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <Row className="border-top m-0">

                      <Col xl="4" md="6" sm="6">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-cur-dollar"></i></div>
                          <div className="media-body">
                            <h6>Facturas Anuladas</h6>
                            <p>0</p>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="12" className="pe-0">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-cur-dollar"></i></div>
                          <div className="media-body">
                            <h6>Total Factura Año</h6>
                            <p>{Stotal}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>






        </Row>
      </Container>
    </Fragment>
  );
}

export default Default;