import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Swal from 'sweetalert2';
import authAxios from "../../services/Token";

// Create styles
const styles = StyleSheet.create({
   page: {
      flexDirection: 'row',
      backgroundColor: '#ffffff'
   },
   section: {
      margin: 10,
      padding: 10,
      paddingLeft: 10,
      flexGrow: 1,
      fontSize: 10,
      border: 1
   },
   section2: {
      margin: 10,
      padding: 200,
      paddingLeft: 10,
      flexGrow: 1,
      fontSize: 12,
      alignItems: "center"
   },
   baseText: {
      fontWeight: 'bold'
   },
   innerText: {
      color: 'black',
      fontWeight: 'bold'
   }

});

// Create Document Component
const MyDocument = ({ item }) => {

   const [Cliente, setCliente] = React.useState("Cargando..");
   const [Contrato, setContrato] = React.useState("Cargando..");
   const [Origen, setOrigen] = React.useState("Cargando..");
   const [Destino, setDestino] = React.useState("Cargando..");
   const [Inicio, setInicio] = React.useState("Cargando..");
   const [Fin, setFin] = React.useState("Cargando..");
   const [Placa, setPlaca] = React.useState("Cargando..");
   const [Modelo, setModelo] = React.useState("Cargando..");
   const [Marca, setMarca] = React.useState("Cargando..");
   const [Clase, setClase] = React.useState("Cargando..");
   const [Interno, setInterno] = React.useState("Cargando..");
   const [Tarjeta, setTarjeta] = React.useState("Cargando..");

   const [Nombre, setNombre] = React.useState("Cargando..");
   const [Cedula, setCedula] = React.useState("Cargando..");
   const [Licencia, setLicencia] = React.useState("Cargando..");
   const [Vigencia, setVigencia] = React.useState("Cargando..");

   const [Nombre2, setNombre2] = React.useState("");
   const [Cedula2, setCedula2] = React.useState("");
   const [Licencia2, setLicencia2] = React.useState("");
   const [Vigencia2, setVigencia2] = React.useState("");

   const [Nombre3, setNombre3] = React.useState("");
   const [Cedula3, setCedula3] = React.useState("");
   const [Licencia3, setLicencia3] = React.useState("");
   const [Vigencia3, setVigencia3] = React.useState("");
   const [contratoCargado, setContratoCargado] = React.useState(false);

   React.useEffect(() => {
      VerRegistros(item.id);

   }, [item.id]);

   const VerRegistros = async (fue) => {
      
      Swal.showLoading();
      authAxios.get(`master/fuec/${fue}`)
         .then((datosRespuesta) => {
            
            let veh = datosRespuesta.data.vehiculo;
            let con = datosRespuesta.data.contrato;
            let cli = datosRespuesta.data.cliente;
            let cc = datosRespuesta.data.conductor;
            let cc2 = datosRespuesta.data.conductor2;

            setCliente(cli.nombres + ' ' + cli.apellidos);
            setContrato(con.descripcion);
            setOrigen(veh.origen);
            setDestino(veh.destino);
            setInicio(con.fecha_inicio);
            setFin(con.fecha_fin);
            setPlaca(veh.placa);
            setModelo(veh.modelo);
            //setMarca(veh.marca);
            setClase(veh.clase);
            // setInterno(lista);
            //setTarjeta(lista);

            setNombre(cc.nombre + ' ' + cc.apellidos);
            setCedula(cc.identificacion);
            setLicencia(cc.identificacion);
            setVigencia(cc.fech_vencpase);

            if (cc2 !== null) {
               setNombre2(cc2.nombre + ' ' + cc2.apellidos);
               setCedula2(cc2.identificacion);
               setLicencia2(cc2.identificacion);
               setVigencia2(cc2.fech_vencpase);
             } else {
               setNombre2("");
               setCedula2("");
               setLicencia2("");
               setVigencia2("");
             }
            setContratoCargado(true);

            Swal.close();


         })
         .catch();


   };

   return (
      <>
         {contratoCargado && (
            <Document>
               <Page size="A4" style={styles.page}>
                  <View style={styles.section} >
                     <Text style={{ textAlign: "center" }} >FICHA DEL FORMATO DEL EXTRACTO DEL CONTRATO “FUEC”</Text>
                     <Image src={require("../../assets/images/header.png")} alt="" style={{ width: 550, height: 70, textAlign: "center" }} />

                     <Text style={{ textAlign: "center" }} >FORMATO ÚNICO DEL EXTRACTO DEL CONTRATO DEL SERVICIO PÚBLICO DE TRANSPORTE TERRESTRE</Text>
                     <Text style={{ textAlign: "center" }} >FUEC No.: <Text style={{ color: '#6E6E6E' }}> {item.consecituivo} </Text></Text>
                     <Text>___________________________________________________________________________________________________</Text>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 350 }}>
                           <Text>
                              RAZÓN SOCIAL DE LA EMPRESA DE TRANSPORTE ESPECIAL:
                           </Text>
                        </View>
                        <View style={{ width: 150 }}>
                           <Text>
                              NIT:
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              TRANSPORTES ESPECIALES DEL ORIENTE
                           </Text>
                        </View>
                        <View style={{ width: 150 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              900645942-6
                           </Text>
                        </View>
                     </View>
                     <Text>___________________________________________________________________________________________________</Text>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 350 }}>
                           <Text>
                              CONTRATANTE:
                           </Text>
                        </View>
                        <View style={{ width: 150 }}>
                           <Text>
                              NIT-CC:
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cliente}
                           </Text>
                        </View>
                        <View style={{ width: 150 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {item.nit_cliente}
                           </Text>
                        </View>
                     </View>

                     <Text>__________________________________________________________________________________________________</Text>
                     <Text style={styles.baseText} >OBJETO CONTRATO: <Text style={{ color: '#6E6E6E' }}>{Contrato}</Text></Text>
                     <Text>__________________________________________________________________________________________________</Text>
                     <Text style={styles.baseText} >ORIGEN:<Text style={{ color: '#6E6E6E' }}>{Origen}</Text> </Text>
                     <Text style={{ fontWeight: 'bold', borderBottom: 1 }} >DESTINO: <Text style={{ color: '#6E6E6E' }}>{Destino}</Text></Text>
                     <Text>__________________________________________________________________________________________________</Text>
                     <Text style={{ textAlign: "center", backgroundColor: '#A9D0F5' }} >VIGENCIA DEL CONTRATO</Text>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 250 }}>
                           <Text >
                              FECHA DE INICIO:
                           </Text>
                        </View>
                        <View style={{ width: 250 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Inicio}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 250 }}>
                           <Text >
                              FECHA DE VENCIMIENTO:
                           </Text>
                        </View>
                        <View style={{ width: 250 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Fin}
                           </Text>
                        </View>
                     </View>

                     <Text> </Text>
                     <Text style={{ textAlign: "center", backgroundColor: '#A9D0F5' }} >CARACTERISTICAS DEL VEHICULO</Text>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 125 }}>
                           <Text >
                              PLACA
                           </Text>
                        </View>
                        <View style={{ width: 125 }}>
                           <Text >
                              MODELO
                           </Text>
                        </View>
                        <View style={{ width: 125 }}>
                           <Text >
                              MARCA
                           </Text>
                        </View>
                        <View style={{ width: 125 }}>
                           <Text >
                              CLASE
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 125 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Placa}
                           </Text>
                        </View>
                        <View style={{ width: 125 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Modelo}
                           </Text>
                        </View>
                        <View style={{ width: 125 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Marca}
                           </Text>
                        </View>
                        <View style={{ width: 125 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Clase}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 250 }}>
                           <Text style={{ textAlign: "center" }} >
                              NUMERO INTERNO
                           </Text>
                        </View>
                        <View style={{ width: 250 }}>
                           <Text style={{ textAlign: "center" }} >
                              NUMERO TARJETA DE OPERACION
                           </Text>
                        </View>

                     </View>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 250 }}>
                           <Text style={{ color: '#6E6E6E', textAlign: "center" }}>
                              {Interno}
                           </Text>
                        </View>
                        <View style={{ width: 250 }}>
                           <Text style={{ color: '#6E6E6E', textAlign: "center" }}>
                              {Tarjeta}
                           </Text>
                        </View>

                     </View>
                     <Text style={{ textAlign: "center", backgroundColor: '#A9D0F5' }} >DATOS BASICO CONDUCTOR 1</Text>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              NOMBRE Y APELLIDOS:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Nombre}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. CEDULA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. LICENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              FECHA DE VIGENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Vigencia}
                           </Text>
                        </View>

                     </View>
                     <Text style={{ textAlign: "center", backgroundColor: '#A9D0F5' }} >DATOS BASICO CONDUCTOR 2</Text>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              NOMBRE Y APELLIDOS:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Nombre2}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. CEDULA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula2}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. LICENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula2}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              FECHA DE VIGENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Vigencia2}
                           </Text>
                        </View>

                     </View>
                     <Text style={{ textAlign: "center", backgroundColor: '#A9D0F5' }} >DATOS BASICO CONDUCTOR 3</Text>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              NOMBRE Y APELLIDOS:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Nombre3}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. CEDULA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula3}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. LICENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula3}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              FECHA DE VIGENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Vigencia3}
                           </Text>
                        </View>

                     </View>
                     <Text style={{ textAlign: "center", backgroundColor: '#A9D0F5' }} >DATOS DEL CONTRATANTE</Text>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              NOMBRE Y APELLIDOS:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              SEBASTIAN HURTADO LARREAMENDI
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. CEDULA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              11202032
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              TELEFONO:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              3133333707
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              DIRECCION:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              CRA 69B # 98ª 10
                           </Text>
                        </View>

                     </View>
                     <Text>{'\n'}______________________________________________________________________________________________________{'\n'}{'\n'}</Text>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 280 }}>
                           <Text>
                              {'\n'}{'\n'}¨FORMATO ÚNICO DEL EXTRACTO DEL CONTRATO SEGÚN DECRETO 0348 DE 2015 Y RESOLUCIÓN 0006652 DE 2019¨{'\n'}{'\n'}
                           </Text>
                        </View>
                        <View style={{ width: 220 }}>
                           <Text style={{ color: '#E6E6E6', textAlign: "center" }}>
                              {'\n'}{'\n'}FIRMA Y SELLO DE LA EMPRESA
                           </Text>
                        </View>

                     </View>
                     {'\n'}
                     {'\n'}
                     <Text>______________________________________________________________________________________________________{'\n'}{'\n'}</Text>
                     <Text style={{ textAlign: "center" }}>{'\n'}{'\n'}TRANSPORTES ESPECIALES DEL ORIENTE S.A.S {'\n'}
                        CALLE 64A # 21 - 50 EDIF. PORTAL DEL CABLE {'\n'}
                        TEL. 3045049860 - 3046690903{'\n'}
                        MANIZALES - CALDAS</Text>


                  </View>




               </Page>
            </Document>
         )}
      </>
   )
}

export default MyDocument;