import React from 'react';
import Swal from 'sweetalert2';
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
    f[2].padStart(2, "0") +
    "-" +
    f[1].padStart(2, "0") +
    "-" +
    f[0].padStart(2, "0");

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            ListaTabla: [],
            ListaEstados: [],
            ListaCliente: [],
            ListaCiudades: [],
            ListaCiudadesFiltradas: [],
            ListaDepartamentos: [],
            ListaAseguradoras: [],
            ListaPrefactura: [],
            Hoy: hoy,
            seleccionarTodo: false,
            idsSeleccionados: [],
            clientesConBoton: [],
            idClienteInfo: [],
            Usuario: localStorage.getItem('Name'),
            showModal: false,
            selectedImage: '',
            showModalPdf: false,
            selectedPdfid: '',
        }
    }

    // Agrega un manejador de eventos para el cambio en el campo de selección de departamento
    handleDepartamentoChange = (event) => {
        const departamentoSeleccionado = event.target.value;

        // Filtra la lista de ciudades en función del departamento seleccionado
        const ciudadesFiltradas = this.state.ListaCiudades.filter(
            (ciudad) => ciudad.departamento === departamentoSeleccionado
        );

        this.setState({
            ListaCiudadesFiltradas: ciudadesFiltradas,
        });
    };
    exportToExcel = () => {
        let idx = document.getElementById('idprefactura').value;
        const { ListaPrefactura } = this.state;

        const ws = XLSX.utils.json_to_sheet(ListaPrefactura);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');

        XLSX.writeFile(wb, 'Prefactura' + idx + '.xlsx');
    };
    consultarClienteId(identificacion) {
        console.log(this.state.clientesConBoton)
        Swal.showLoading();
        authAxios.post("exterior/consulta-id-cliente/" + identificacion)
            .then((datosRespuesta) => {
                Swal.close();
                var respuesta = datosRespuesta.data.datos[0];
                console.log(datosRespuesta.data);
                // document.getElementById("tipoDocumento").value = respuesta.tipoDocumento;
                document.getElementById("nombres").value = respuesta.nombre;
                document.getElementById("telefono").value = respuesta.telefono;
                // document.getElementById("departamento").value = respuesta.departamento;
                document.getElementById("documento").value = respuesta.identificacion;
                document.getElementById("email").value = respuesta.email;
                document.getElementById("apellidos").value = respuesta.apellido;
                // document.getElementById("ciudad").value = respuesta.ciudad;
                document.getElementById("direccion").value = respuesta.direccion;

                // this.setState({ idClienteInfo: datosRespuesta.data.datos });
                // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    guardarCliente = (e) => {

        e.preventDefault();
        Swal.showLoading();
        // Recopila los datos del formulario en un objeto data
        const data = {
            tipoDocumento: document.getElementById('tipoDocumento').value,
            nombres: document.getElementById('nombres').value,
            telefono: document.getElementById('telefono').value,
            departamento: document.getElementById('departamento').value,
            documento: document.getElementById('documento').value,
            email: document.getElementById('email').value,
            apellidos: document.getElementById('apellidos').value,
            ciudad: document.getElementById('ciudad').value,
            direccion: document.getElementById('direccion').value,
        };
        this.setState({ clientesConBoton: [] }, () => {
            // Código que depende del estado actualizado
            // Realiza la solicitud a la API con los datos del formulario
            authAxios.post('exterior/guardar-cliente', data)
                .then(response => {
                    Swal.close();
                    console.log(this.state.clientesConBoton)
                    this.MostrarTabla(1);
                    // Maneja la respuesta de la API si es necesario
                    console.log(response.data);
                    const miBoton = document.getElementById('miBoton');

                    // Simula un clic en el botón
                    miBoton.click();
                })
                .catch(error => {
                    // Maneja los errores si la solicitud falla
                    console.error(error);
                });
        });

    }
    MostrarTabla() {
        this.setState({ clientesConBoton: [] });
        Swal.showLoading();
        let bfecha = document.getElementById('bfecha').value;
        let bfecha2 = document.getElementById('bfecha2').value;
        let origen = document.getElementById('borigen').value;
        let estado = document.getElementById('bestado').value;


        if (bfecha == '' || bfecha2 == '') {
            Swal.fire(
                'Debes de seleccionar la fecha inicial y final',
                '',
                'warning'
            )
            return false;
        }
        var datos = { origen: origen, fechai: bfecha, fechaf: bfecha2, estado: estado }
        this.setState({ clientesConBoton: [] }, () => {
            authAxios.post("exterior/consultapreoperacional", datos)
                .then((datosRespuesta) => {
                    Swal.close();
                    var status = datosRespuesta.data.status;

                    this.setState({ datosCargados: true, load: '', ListaTabla: datosRespuesta.data.datos, clientesConBoton: [] });
                })
                .catch(() => {
                    this.setState({ clientesConBoton: [] });
                });
        })
        //window.location.href = '/'; window.localStorage.clear();
    }
    MostrarClientes() {

        authAxios.get("exterior/consultacliente")
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                console.log(datosRespuesta.data);
                this.setState({
                    datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras, ListaCiudades: datosRespuesta.data.ciudades,
                    ListaDepartamentos: datosRespuesta.data.departamentos
                });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    Enviar = () => {
        let id = document.getElementById('idprefactura').value;
        var dati = { id: id }
        authAxios.post("exterior/consultarprefactura", dati)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                console.log(datosRespuesta.data.datos);
                this.setState({ ListaPrefactura: datosRespuesta.data.datos });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    UpValores(id) {
        const { idsSeleccionados } = this.state;
        let valor = document.getElementById('valor' + id).value;
        var datos = { id: id, valor: valor }
        console.log(idsSeleccionados);
        authAxios.post("exterior/actualizavalor", datos)
            .then((datosRespuesta) => {

                var status = datosRespuesta.data.status;

            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    GuardarValores = () => {
        Swal.fire({
            title: '¿Está seguro de importar los servicios?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Importar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let origen = document.getElementById('borigen').value;
                const { idsSeleccionados, Usuario } = this.state;
                if (idsSeleccionados.length === 0) {
                    Swal.fire(
                        'Debes de seleccionar los servicios',
                        '',
                        'warning'
                    );
                    return false;
                }

                var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
                Swal.showLoading();
                authAxios.post("exterior/guardarseleccionado-servicios-externos", datos)
                    .then((datosRespuesta) => {
                        var m = datosRespuesta.data.msj;
                        var status = datosRespuesta.data.status;
                        var servicios = datosRespuesta.data.servicios_creados;
                        var errores = datosRespuesta.data.vh_no_creados;

                        var serviciosHtml = '';
                        servicios.forEach((servicio, index) => {
                            serviciosHtml += `
                                <tr>
                                    <th scope="row">${index + 1}</th>
                                    <td>${servicio}</td>
                                </tr>
                            `;
                        });
                        var erroresHtml = '';
                        errores.forEach((servicio, index) => {
                            erroresHtml += `
                                <tr>
                                    <th scope="row">${index + 1}</th>
                                    <td>${servicio}</td>
                                </tr>
                            `;
                        });
                        Swal.fire({
                            title: 'Servicios Importados',
                            icon: 'success',
                            allowOutsideClick: false,
                            html: `
                                <ul class="nav nav-pills mb-3" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Resueltos</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Errores</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <h6>Servicios creados</h6>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Servicios</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    ${serviciosHtml}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <h6>Servicios no creados</h6>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">VEHICULOS NO CREADOS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                ${erroresHtml}
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            `,
                            showConfirmButton: true, // Permitir al usuario cerrar manualmente
                            confirmButtonText: 'Cerrar',
                            didClose: () => {
                                // Esta función se ejecutará cuando el usuario cierre el SweetAlert
                                this.MostrarTabla();
                            }
                        });


                    })
                    .catch(error => {
                        console.error('Error al importar los servicios:', error);
                    });
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info');
            }
        });
    }

    AnularValores = () => {
        Swal.fire({
            title: 'Está seguro de Anular los servicios seleccionados?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Generar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                let origen = document.getElementById('borigen').value;
                const { idsSeleccionados, Usuario } = this.state;
                if (idsSeleccionados.length === 0) {
                    Swal.fire(
                        'Debes de seleccionar los servicios',
                        '',
                        'warning'
                    )
                    return false;
                }

                var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
                console.log(idsSeleccionados);
                authAxios.post("exterior/anularseleccionado", datos)
                    .then((datosRespuesta) => {
                        var m = datosRespuesta.data.msj;
                        var status = datosRespuesta.data.status;
                        Swal.fire({
                            title: m,
                            text: 'Facturado',
                            icon: 'success',
                            showConfirmButton: true, // Mostrar el botón "Confirmar"
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.MostrarTabla();
                            }
                        });

                    })
                    .catch();
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    }
    handleSelectAll = () => {
        this.setState((prevState) => {
            const { ListaTabla, seleccionarTodo, idsSeleccionados } = prevState;

            let nuevosIdsSeleccionados = [];

            if (!seleccionarTodo) {
                // Si seleccionarTodo es falso, selecciona todos los IDs
                nuevosIdsSeleccionados = ListaTabla.map((item) => item.id);
            }
            console.log(nuevosIdsSeleccionados)
            return {
                seleccionarTodo: !seleccionarTodo,
                idsSeleccionados: nuevosIdsSeleccionados,
            };

        });
    };

    handleCheckboxChange = (id) => {
        this.setState((prevState) => {
            const { idsSeleccionados } = prevState;

            const nuevosIdsSeleccionados = [...idsSeleccionados];
            const index = nuevosIdsSeleccionados.indexOf(id);

            if (index === -1) {
                nuevosIdsSeleccionados.push(id);
            } else {
                nuevosIdsSeleccionados.splice(index, 1);
            }

            return { idsSeleccionados: nuevosIdsSeleccionados };
        });
    };
    componentDidMount() {

        this.MostrarClientes(1);

    }
    // handleShow = (image) => {
    //     this.setState({ showModal: true, selectedImage: image }, () => {
    //         $('#imageModal').modal('show');
    //     });
    // };
    // handleShowpdf = (image) => {
    //     this.setState({ showModalPdf: true, selectedPdfid: image }, () => {
    //         $('#imageModalPdf').modal('show');
    //     });
    // };

    // handleClose = () => {
    //     this.setState({ showModal: false, selectedImage: '' }, () => {
    //         $('#imageModal').modal('hide');
    //     });
    // };
    render() {
        const { load, ListaCiudadesFiltradas, ListaTabla, pagina, ultimo, ListaPrefactura, actual, Hoy, ListaAseguradoras, ListaEstados, ListaCliente, seleccionarTodo, idsSeleccionados, ListaDepartamentos, ListaCiudades } = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="card">

                        <div className="card-body">

                            <h4>PREOPERACIONALES TODOS</h4>
                            <hr />
                            <div className="row">
                                <div className="col-xl-2">
                                    <select id="borigen" name="borigen" className="form-control form-control-sm" >
                                        <option value={''}>Seleccione el conductor</option>
                                        {
                                            ListaCliente.map((item, i) => (
                                                <option value={item.id} >{item.id} - {item.nombre}</option>
                                            ))}
                                    </select>
                                </div>
                                <div className="col-xl-2">
                                    <select id="bestado" name="bestado" className="form-control form-control-sm" >
                                        <option value={''}>Seleccione vehiculo</option>
                                        {
                                            ListaEstados.map((ite, i) => (
                                                <option key={ite.id} value={ite.id}>{ite.id} - {ite.nombre}</option>
                                            ))}
                                    </select>
                                </div>
                                <div className="col-xl-2">
                                    <input style={{ textTransform: 'uppercase' }} type="date" id="bfecha" name="bfecha" placeholder="Buscar por expediente" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-2">
                                    <input style={{ textTransform: 'uppercase' }} type="date" id="bfecha2" name="bfecha2" placeholder="Buscar por fecha" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-2">
                                    <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.MostrarTabla(1)}>&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row table-responsive">
                                <table className="table table-hover table-condensed table-sm">

                                    <thead className="thead-inverse  table-success ">
                                        <tr>
                                            <th className="align-middle">#</th>
                                            <th className="align-middle">ID</th>
                                            <th className="align-middle">VEHICULO</th>
                                            <th className="align-middle">CONDUCTOR</th>
                                            <th className="align-middle">KILOMETRAJE</th>
                                            <th className="align-middle">IMAGEN ODOMETRO</th>
                                            <th className="align-middle">FECHA</th>
                                            <th className="align-middle">PDF PREOPERACIONAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {

                                            ListaTabla.map((item, i) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{i + 1}</td>
                                                        <td>{item.id}</td>
                                                        <td>{item.placa}</td>
                                                        <td>{item.nombre} {item.apellidos}</td>
                                                        <td>{item.kilometraje}</td>
                                                        <td>
                                                            <img
                                                                src={'https://app.transorientesas.com/storage/' + item.imagen}
                                                                alt="thumbnail"
                                                                style={{ width: '50px', cursor: 'pointer' }}
                                                                onClick={() => this.handleShow(item.imagen)}
                                                            />
                                                        </td>
                                                        <td>{item.fecha}</td>
                                                        <td><button style={{'background':'#00AA4F'}} className='btn' onClick={() => this.handleShowpdf(item.imagen)}>VER PDF</button></td>
                                                    </tr>
                                                );
                                            })
                                        }


                                    </tbody>

                                </table>

                            </div>

                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="imageModal" tabIndex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="imageModalLabel">Imagen Grande</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <img src={'https://app.transorientesas.com/storage/' + this.state.selectedImage} alt="large view" style={{ width: '100%' }} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <style>
                {`
                    #modalbody td {
                        margin-top: 2px;
                        margin-left: 5px;
                        margin-right: 5px;
                        margin-bottom: 2px;
                        font-family: Arial, sans-serif;
                        border: 1px solid;
                        color: #000;
                    }

                    .Estilo4 {
                        font-size: 0.7em;
                    }

                    .writetd {
                        font-size: 0.7em;
                    }

                    .date_input {
                        border: 0px;
                        background-color: rgba(255, 255, 255, .5);
                        visibility: hidden;
                    }

                    .input_lists {
                        border: 0px;
                        background-color: rgba(255, 255, 255, .5);
                    }

                    .button_input {
                        border: 0px;
                        background-color: rgba(255, 255, 255, .5);
                    }

                    .button_input:hover {
                        background-color: red;
                    }

                    .theader {
                        background-color: #00AA4F !important;
                        font-weight: bold;
                        font-size: 0.8em;
                        color: #000;
                    }
                `}
            </style>
                <div className="modal fade" id="imageModalPdf" tabIndex="-1" role="dialog" aria-labelledby="imageModalPdfLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="imageModalLabel">PREOPERACIONAL FORMATO</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" id="modalbody">
                                <table width='100%' class='table_image' border cellspacing='0' align='center'>
                                    <tr>
                                        <td width='100%' rowspan='3' align='center' valign='middle'>
                                            <img src='https://app.transorientesas.com/static/media/logov2.7e4832492b0a9b08f12e.png'
                                                border='0' class='print_image' height='50' width='300' />
                                        </td>
                                    </tr>
                                </table>
                                <table border='0' cellspacing='0' cellpadding='0' width='100%' align='center'
                                    class="mt-4">
                                </table>



                                <table cellspacing='0' width="100%" border='1'>

                                    <tr height='24'>
                                        <td width='82' height='24' class='Estilo4 theader'>PLACA:</td>
                                        <td colspan='7' class='Estilo4'>
                                            <input class="input_lists" id="placa_lists" disabled />
                                        </td>
                                        <td width='78' class='Estilo4 theader'>Fecha:</td>
                                        <td colspan='5' class='Estilo4' ><input class="input_lists"
                                            id="einput0" disabled /></td>
                                    </tr>
                                    <tr height='24'>
                                        <td height='24' colspan='4' class='Estilo4 theader'>Responsable inspecci&oacute;n:
                                        </td>
                                        <td colspan='10' class='Estilo4' class='writetd' contenteditable="true"><input
                                            class="input_lists" id="responsable_ins" disabled /></td>
                                    </tr>
                                    <tr height='8'>
                                        <td height='8' colspan='14' class='Estilo4'>&nbsp;</td>
                                    </tr>
                                    <tr height='20'>
                                        <td height='38' colspan='5' rowspan='2' class='Estilo4 theader'>
                                            <div align='center'>TIPO</div>
                                        </td>
                                        <td colspan='6' rowspan='2' width="20%" class='Estilo4 theader'>
                                            <div align='center'>CRITERIO</div>
                                        </td>
                                        <td colspan='2' class='Estilo4 theader'>
                                            <div align='center'>CONFORME</div>
                                        </td>
                                        <td width='257' rowspan='2' class='Estilo4 theader'>
                                            <div align='center'>OBSERVACIONES</div>
                                        </td>
                                    </tr>
                                    <tr height='18'>
                                        <td width='40' height='18' class='Estilo4 theader'>
                                            <div align='center'>SI</div>
                                        </td>
                                        <td width='46' class='Estilo4 theader'>
                                            <div align='center'>NO</div>
                                        </td>
                                    </tr>

                                    <tr height='20'>
                                        <td height='54' colspan='2' rowspan='5' class='Estilo4 theader'>DOCUMENTOS</td>
                                        <td colspan='3' class='Estilo4'>Licencia de conduccion</td>
                                        <td colspan='6' rowspan='5' class='Estilo4'>Verificar su presencia y fecha de
                                            vigencia adecuada</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='licencia_conduccion' />
                                            </div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='licencia_conduccionNo' />
                                            </div>
                                        </td>
                                        <td class='Estilo4' rowspan="5" class='writetd' contenteditable="true"
                                            id="obs_documentos">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Tarjeta propiedad</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='documentos' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='documentosNo' /></div>
                                        </td>

                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>SOAT</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='soat' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='soatNo' /></div>
                                        </td>

                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Contrato</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='contrato' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='contratoNo' /></div>
                                        </td>

                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Colilla de Manteminiento</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='colilla_mantenimiento' />
                                            </div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='colilla_mantenimientoNo' />
                                            </div>
                                        </td>

                                    </tr>

                                    <tr height='17'>
                                        <td height='17' colspan='2' class='Estilo4 theader'>LLAVES</td>
                                        <td colspan='9' class='Estilo4'>Estado físico completo, llaves funcionales para
                                            accionar.</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='llaves' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='llavesNo' /></div>
                                        </td>
                                        <td class='Estilo4' class='writetd' contenteditable="true" id="obs_llaves">&nbsp;</td>
                                    </tr>

                                    <tr height='20'>
                                        <td height='37' colspan='2' rowspan='2' class='Estilo4 theader'>
                                            CALCOMAN&Iacute;AS</td>
                                        <td colspan='3' class='Estilo4'>L&iacute;nea asistencia</td>
                                        <td colspan='6' rowspan='2' class='Estilo4'>Verificar su presencia y fecha de
                                            vigencia adecuada</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='calco' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='calcoNo' /></div>
                                        </td>
                                        <td class='Estilo4' class='writetd' rowspan="2" contenteditable="true"
                                            id="obs_calcomanias">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Prohibido fumar</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='prohibido' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='prohibidoNo' /></div>
                                        </td>
                                    </tr>

                                    <tr height='20'>
                                        <td height='37' colspan='2' rowspan='2' class='Estilo4 theader'>DIRECCIONALES
                                        </td>
                                        <td colspan='3' class='Estilo4'>Delanteras</td>
                                        <td colspan='6' rowspan='2' class='Estilo4'>Funcionamiento y respuesta inmediata
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='delantero' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='delanteroNo' /></div>
                                        </td>
                                        <td class='Estilo4' rowspan='2' class='writetd' contenteditable="true"
                                            id="obs_direccionales">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Traseras</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='trasera' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='traseraNo' /></div>
                                        </td>
                                    </tr>

                                    <tr height='20'>
                                        <td height='88' colspan='2' rowspan='5' class='Estilo4 theader'>LUCES</td>
                                        <td colspan='3' class='Estilo4'>Altas</td>
                                        <td colspan='6' rowspan='5' class='Estilo4'>Funcionamiento de bombillos, cubiertas
                                            sin roturas, respuesta
                                            inmediata</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='altas' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='altasNo' /></div>
                                        </td>
                                        <td class='Estilo4' rowspan='5' class='writetd' contenteditable="true"
                                            id="obs_luces">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Bajas</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='bajas' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='bajasNo' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Stops</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='stops' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='stopsNo' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Reversa</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='reversa' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='reversaNo' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Parqueo</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='parqueo' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='parqueoNo' /></div>
                                        </td>
                                    </tr>

                                    <tr height='17'>
                                        <td height='68' colspan='2' rowspan='4' class='Estilo4 theader'>LLAVES</td>
                                        <td colspan='3' class='Estilo4'>Carcasa</td>
                                        <td colspan='6' class='Estilo4'>Sin roturas</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='llaves2' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='llaves2' /></div>
                                        </td>
                                        <td class='Estilo4' rowspan='4' class='writetd' contenteditable="true">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Llavero</td>
                                        <td colspan='6' class='Estilo4'>Coincide la placa y est&aacute; en buen estado</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='llavero' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='llavero' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Bater&iacute;a</td>
                                        <td colspan='6' class='Estilo4'>Funcionando y tapada</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='bateria' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='bateria' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Botones</td>
                                        <td colspan='6' class='Estilo4'>Funcionando</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='botones' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='botones' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='2' class='Estilo4 theader'>PLUMILLAS</td>
                                        <td colspan='3' class='Estilo4'>Der / Izq / Atr&aacute;s</td>
                                        <td colspan='6' class='Estilo4'>Estado, limpieza y funcionamiento</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='plumillas' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='plumillas' /></div>
                                        </td>
                                        <td class='Estilo4' class='writetd' contenteditable="true">&nbsp;</td>
                                    </tr>
                                    <tr height='20'>
                                        <td height='37' colspan='2' rowspan='2' class='Estilo4 theader'>FRENOS</td>
                                        <td colspan='3' class='Estilo4'>Principal</td>
                                        <td colspan='6' rowspan='2' class='Estilo4'>Verificar antes de iniciar un servicio
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='frenos' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='frenos' /></div>
                                        </td>
                                        <td class='Estilo4' rowspan='2' class='writetd' contenteditable="true">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Emergencia</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='emergencia' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='emergencia' /></div>
                                        </td>
                                    </tr>
                                    <tr height='20'>
                                        <td height='37' colspan='2' rowspan='3' class='Estilo4 theader'>LLANTAS</td>
                                        <td colspan='3' class='Estilo4'>Delanteras</td>
                                        <td colspan='6' rowspan='3' class='Estilo4'>Verificar presi&oacute;n y profundidad
                                            de labrado</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='teras' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='teras' /></div>
                                        </td>
                                        <td class='Estilo4' rowspan='3' class='writetd' contenteditable="true">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Traseras</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='seras' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='seras' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Repuestos</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='repuestos' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='repuestos' /></div>
                                        </td>
                                    </tr>

                                    <tr height='20'>
                                        <td height='37' colspan='2' rowspan='2' class='Estilo4 theader'>ESPEJOS</td>
                                        <td colspan='3' class='Estilo4'>Laterales</td>
                                        <td colspan='6' rowspan='2' class='Estilo4'>Verificar cubiertas sin roturas y
                                            limpieza</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='espejo' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='espejo' /></div>
                                        </td>
                                        <td class='Estilo4' rowspan='2' class='writetd' contenteditable="true">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Retrovisor</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='retrovisor' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='retrovisor' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='2' class='Estilo4 theader'>PITO</td>
                                        <td colspan='9' class='Estilo4'>Verificar que responde adecuadamente</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='pito' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='pito' /></div>
                                        </td>
                                        <td class='Estilo4' class='writetd' contenteditable="true">&nbsp;</td>
                                    </tr>
                                    <tr height='20'>
                                        <td height='54' colspan='2' rowspan='3' class='Estilo4 theader'>FLUIDOS</td>
                                        <td colspan='3' class='Estilo4'>Frenos</td>
                                        <td colspan='6' rowspan='3' class='Estilo4'>Verificar niveles y reportar fugas</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='enos' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='enos' /></div>
                                        </td>
                                        <td class='Estilo4' rowspan='3' class='writetd' contenteditable="true">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Aceite</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='aceite' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='aceite' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Refrigerante</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='rante' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='rante' /></div>
                                        </td>
                                    </tr>
                                    <tr height='20'>
                                        <td height='37' colspan='2' rowspan='2' class='Estilo4 theader'>APOYA
                                            CABEZAS</td>
                                        <td colspan='3' class='Estilo4'>Delanteros</td>
                                        <td colspan='6' rowspan='2' class='Estilo4'>Verificar su presencia y estado</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='apo_del' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='apo_del' /></div>
                                        </td>
                                        <td class='Estilo4' rowspan='2' class='writetd' contenteditable="true">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Traseros</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='apo_tra' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='apo_tra' /></div>
                                        </td>
                                    </tr>
                                    <tr height='20'>
                                        <td height='37' colspan='2' rowspan='2' class='Estilo4 theader'>CINTURONES DE
                                            SEGURIDAD</td>
                                        <td colspan='3' class='Estilo4'>Delanteros</td>
                                        <td colspan='6' rowspan='2' class='Estilo4'>Verificar su presencia y estado</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='cin_del' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='cin_del' /></div>
                                        </td>
                                        <td class='Estilo4' rowspan='2' class='writetd' contenteditable="true">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Traseros</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='cin_tra' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='cin_tra' /></div>
                                        </td>
                                    </tr>
                                    <tr height='20'>
                                        <td height='295' colspan='2' rowspan='9' class='Estilo4 theader'>HERRAMIENTA Y
                                            KIT DE CARRETERA</td>
                                        <td colspan='9' class='Estilo4'>Alicate, destornilladores, llaves de expansi&oacute;n y
                                            llaves fijas</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='alicate' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='alicate' /></div>
                                        </td>
                                        <td class='Estilo4' rowspan='9' class='writetd' contenteditable="true">&nbsp;</td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Extintor</td>
                                        <td colspan='6' class='Estilo4'>Fecha vencimiento</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='extintor' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='extintor' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Cruceta</td>
                                        <td colspan='6' class='Estilo4'>Adecuado para las tuercas</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='cruceta' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='cruceta' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Linterna</td>
                                        <td colspan='6' class='Estilo4'>Presente y con bater&iacute;a</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='linterna' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='linterna' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Gato</td>
                                        <td colspan='6' class='Estilo4'>Que soporte el peso</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='gato' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='gato' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Tacos</td>
                                        <td colspan='6' class='Estilo4'>2 tacos</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='tacos' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='tacos' /></div>
                                        </td>
                                    </tr>
                                    <tr height='33'>
                                        <td height='33' colspan='3' class='Estilo4 '>Se&ntilde;ales</td>
                                        <td colspan='6' class='Estilo4'>2 se&ntilde;ales de forma triangular, reflectivas y con
                                            soporte</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='senal' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='senal' /></div>
                                        </td>
                                    </tr>
                                    <tr height='17'>
                                        <td height='17' colspan='3' class='Estilo4'>Chaleco</td>
                                        <td colspan='6' class='Estilo4'>Reflectivo</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='reflectivo' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='reflectivo' /></div>
                                        </td>
                                    </tr>
                                    <tr height='140'>
                                        <td height='140' colspan='3' class='Estilo4'>Botiqu&iacute;n</td>
                                        <td colspan='6' class='Estilo4'>Yodoplvidona soluci&oacute;n antis&eacute;ptico bolsa
                                            (120ml), jab&oacute;n,
                                            gasas, curas, venda el&aacute;stica, mlcropore rollo, algod&oacute;n paquete (25 gr),
                                            acetaminof&eacute;n
                                            tabletas, mareol tabletas, sales de rehldratacl&oacute;n oral, baja lenguas, suero
                                            fisiol&oacute;gico bolsa
                                            (250 mi). guantes latex desechables, toallas higi&eacute;nicas, tijeras y
                                            term&oacute;metro oral.</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='botiquin' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='botiquin' /></div>
                                        </td>
                                    </tr>
                                    <tr height='20'>
                                        <td height='20' colspan='2' class='Estilo4 theader'>MOTOR</td>
                                        <td colspan='3' class='Estilo4'>Moto ventilador</td>
                                        <td colspan='6' class='Estilo4'>Verifica giro de moto ventilador</td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='ventilador' /></div>
                                        </td>
                                        <td class='Estilo4'>
                                            <div style={{'textAlign':'center'}}><input type='radio' name='ventilador' /></div>
                                        </td>
                                        <td class='Estilo4' class='writetd' contenteditable="true">&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td colspan="14" class="theader"><b>OBSERVACIONES GENERALES</b></td>
                                    </tr>
                                    <tr>
                                        {/* <td colspan="14" ><br><br><br><br></td> */}
                                    </tr>
                                </table>
                                        </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                            </div>
                                        </div>
                                    </div>
                            </div>

                        </div>
                       




                  
                    );
    }
}

                    export default Listar;